import type { IPoll, ITransactionPollsQuery, ITransactionPolls, IPollSaveBody } from "~/typings/api/";
import { urls } from "~/api/config";
import { API_SHOP_TYPE } from "~/constants/api";

export default () => {
  const requestPoll = (id: string, meta: string = "") => {
    return useTransport<IPoll>(
      `${urls.userReviews.poll}/${id}`,
      {
        permissions: { jwt: true },
        query: { meta },
      },
    );
  };

  const requestUserPoll = () => {
    return useTransport<IPoll>(
      urls.userReviews.userPoll,
      {
        permissions: { jwt: true },
      },
    );
  };

  const markUserPoll = () => {
    return useTransport<IPoll>(
      urls.userReviews.userPollDisplayed,
      {
        method: "POST",
        permissions: { jwt: true },
        body: {
          poll_name: "today",
          poll_type: "product",
        },
      },
    );
  };

  const getTransactionPolls = (params: ITransactionPollsQuery) => {
    return useTransport<ITransactionPolls>(
      urls.userReviews.transactionPoll(params.transactionId),
      {
        permissions: { jwt: true },
        query: {
          storeCode: params.storeCode,
          storeType: API_SHOP_TYPE,
          productId: params.productId,
        },
      },
    );
  };

  const saveFeedback = (body: IPollSaveBody) => {
    return useTransport<IPoll>(
      urls.userReviews.objectFeedback,
      {
        method: "POST",
        permissions: { jwt: true },
        body,
      },
    );
  };

  return {
    requestPoll,
    requestUserPoll,
    markUserPoll,
    getTransactionPolls,
    saveFeedback,
  };
};
